import React, { useState, useEffect } from 'react'
import { Scene } from 'react-scrollmagic'
import { Timeline, Tween } from 'react-gsap'

import { useTranslation } from 'react-i18next'

import { Container } from '@material-ui/core'

import DettaglioFaseModal from 'components/DettaglioFaseModal/DettaglioFaseModal'
import DettaglioFaseSlider from 'components/DettaglioFaseSlider/DettaglioFaseSlider'
import CustomModal from 'components/CustomModal/CustomModal'
import useProductData from 'hooks/useProductData'

const TraceabilityCard = ({ card }) => {
  const { t } = useTranslation('traceabilityCard')
  const { product } = useProductData()
  const [selectedDettaglio, setSelectedDettaglio] = useState({})
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)

  const [isOpenMoreModal, setIsOpenMoreModal] = useState(false)

  const [cardHeight, setCardHeight] = useState(0)

  const handleClick = (e) => {
    e.preventDefault()
    setIsOpenMoreModal(true)
  }

  useEffect(() => {
    const height = document.getElementsByClassName('traceability-card')[0]?.clientHeight
    setCardHeight(height)
  }, [])

  const body = (
    <article className='traceability-card'>
      <section className='image' style={{ backgroundImage: 'url(' + card.header + ')' }} />
      <section className='title'>
        <Container>
          <h1>{card.title}</h1>
        </Container>
      </section>
      <section className='description'>
        <Container>
          {card.shortText
            ? <>{card.shortText} <a href='#' onClick={handleClick}>{t(`${product.companyCodArticle}.showMore`)}</a></>
            : card.text}
        </Container>
      </section>
      {product.companyCodArticle !== '5251100' &&
        <section className='button'>
          <Container>
            {card.dettaglio.circleCover
              ? <div className='cover-image'><img src={card.dettaglio.circleCover} alt='' /></div>
              : <DettaglioFaseSlider
                items={card.dettaglio}
                setIsOpen={setIsOpenDetailModal}
                setSelectedDettaglio={setSelectedDettaglio}
              />
            }
          </Container>
        </section>
      }
    </article>
  )

  return (
    <>
      {isOpenMoreModal &&
        <CustomModal
          isOpen={isOpenMoreModal}
          setIsOpen={setIsOpenMoreModal}
          className='more-text-modal'
          closeButton
        >
          <section id='header'>
            <div className='image' style={{ backgroundImage: 'url(' + card.header + ')', backgroundSize: 'cover' }} />
          </section>
          <section id='body'>
            <Container id='text'>
              <p>{card.text}</p>
            </Container>
          </section>
        </CustomModal>}
      {isOpenDetailModal &&
        <DettaglioFaseModal
          item={selectedDettaglio}
          isOpen={isOpenDetailModal}
          setIsOpen={setIsOpenDetailModal}
        />}
      {card.id < 3
        ? (
          <Scene
            pin
            duration={cardHeight}
            pinSettings={{ pushFollowers: false }}
            triggerHook='onLeave'
          >
            <Timeline target={body}>
              <Tween duration={1} />
              <Tween
                duration={1}
                from={{ opacity: 1 }}
                to={{ opacity: 0 }}
              />
              <Tween duration={1} />
            </Timeline>
          </Scene>
          )
        : body}
    </>
  )
}

export default TraceabilityCard
