require('dotenv').config()
const manifest = require('../package.json')
const configuration = {
  logTag: process.env.REACT_APP_APIO_LOG || null,
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  environment: process.env.REACT_APP_ENV,
  token: process.env.REACT_APP_API_TOKEN,
  blockchainExplorer: 'https://www.blockchain.com',
  sentry: {
    dsn: 'https://51128a6a2ae949988eb350fc8b7977d6@sentry.apio.cloud/18'
  },
  staticMap: {
    apiUrl: 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/',
    config: ',6.63,0/307x133?access_token=',
    accessToken: 'pk.eyJ1IjoidHJ1c3R5IiwiYSI6ImNrczRndDBlaDJuZ2MycXM3YmVhazI2bTkifQ.bf9HXuv43mEsAPbAMzx2LA'
  },
  companyId: 'fe77d4ad-6cc1-46d2-98b1-02de0598ea9c'
}

export default configuration
