import React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, Container } from '@material-ui/core'

import felsineoLogoImage from 'assets/img/felsineo-logo.png'
import marchioStorico from 'assets/img/company-page/marchio-storico.png'

import contactAddressIcon from 'assets/img/company-page/contact/address.svg'
import contactEshopIcon from 'assets/img/company-page/contact/eshop.svg'
import contactFaxIcon from 'assets/img/company-page/contact/fax.svg'
import contactMailIcon from 'assets/img/company-page/contact/mail.svg'
import contactPivaIcon from 'assets/img/company-page/contact/piva.svg'
import contactTelephoneIcon from 'assets/img/company-page/contact/telephone.svg'

const CompanyPage = () => {
  const { t } = useTranslation('companyPage')

  return (
    <div id='company-page'>
      <section id='header' />

      <section className='rounded-page'>
        <Container>

          <article id='azienda'>
            <h1>{t('company.title')}</h1>
            <p>{t('company.text1')}</p>
          </article>

          <article id='marchio-storico'>
            <div className='logo-circle'>
              <div className='circle' />
              <img src={marchioStorico} alt='Marchio Storico' />
            </div>
          </article>

          <article>
            <p>{t('company.text2')}</p>
          </article>

          <article id='numeri'>
            <div className='logo-circle'>
              <div className='circle' />
              <img src={felsineoLogoImage} alt='Felsineo FMV' />
            </div>
            <ul>
              {/* <li>
                <h1>1°</h1>
                <h3>{t('numbers.1')}</h3>
              </li> */}
              <li>
                <h1>130</h1>
                <h3>{t('numbers.2')}</h3>
              </li>
              <li>
                <h1>400</h1>
                <h3>{t('numbers.4')}</h3>
              </li>
              <li>
                <h1>5</h1>
                <h3>{t('numbers.5')}</h3>
              </li>
              <li>
                <h1>&euro; 50.000.000</h1>
                <h3>{t('numbers.6')}</h3>
              </li>
            </ul>
          </article>

          <article id='contact'>
            <h1>Felsineo S.p.A. - Società Benefit</h1>
            <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2845.6829748768328!2d11.231537165198473!3d44.5011623791014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fd6e74d8fe4e7%3A0x1fe09f4050900dc8!2sFelsineo!5e0!3m2!1sit!2sit!4v1630333276457!5m2!1sit!2sit' allowFullScreen loading='lazy' />
            <Grid container id='detail' alignItems='center'>
              <Grid item xs={3}><img src={contactAddressIcon} alt='' /></Grid>
              <Grid item xs={9}>
                {t('contact.address')}<br />
                Via C. Masetti, 8 - 10, 40069 Zola Predosa (BO) - Italia
              </Grid>
              <Grid item xs={3}><img src={contactPivaIcon} alt='' /></Grid>
              <Grid item xs={9}>
                {t('contact.cfisc')} 00291700375<br />
                {t('contact.piva')} 00499291201
              </Grid>
              <Grid item xs={3}><img src={contactTelephoneIcon} alt='' /></Grid>
              <Grid item xs={9}>
                Tel:<br />
                +39 051 3517011
              </Grid>
              <Grid item xs={3}><img src={contactFaxIcon} alt='' /></Grid>
              <Grid item xs={9}>
                Fax:<br />
                +39 051 3517101
              </Grid>
              <Grid item xs={3}><img src={contactMailIcon} alt='' /></Grid>
              <Grid item xs={9}>
                Email:<br />
                <a href='mailto: info@felsineo.com'>info@felsineo.com</a>
              </Grid>
              <Grid item xs={3}><img src={contactEshopIcon} alt='' /></Grid>
              <Grid item xs={9}>
                {t('contact.site')}<br />
                <a href='https://www.felsineo.com/' target='_blank' rel='noreferrer'>www.felsineo.com</a>
              </Grid>
            </Grid>
          </article>

        </Container>
      </section>
    </div>
  )
}

export default CompanyPage
