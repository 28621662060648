import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

// import logoCircle1 from 'assets/img/product-page/loghi-circle/it/1.png'
// import logoCircle2 from 'assets/img/product-page/loghi-circle/it/2.png'
// import logoCircle3 from 'assets/img/product-page/loghi-circle/it/3.png'

import enLogoCircle1 from 'assets/img/product-page/loghi-circle/en/1.png'
import enLogoCircle2 from 'assets/img/product-page/loghi-circle/en/2.png'
import enLogoCircle3 from 'assets/img/product-page/loghi-circle/en/3.png'

import image1 from 'assets/img/product-page/image1.png'
// import image3 from 'assets/img/product-page/image3.jpg'
import image4 from 'assets/img/product-page/image4.png'

import logo1 from 'assets/img/product-page/loghi/1.svg'
import logo2 from 'assets/img/product-page/loghi/2.svg'
import logo3 from 'assets/img/product-page/loghi/3.svg'
import logo4 from 'assets/img/product-page/loghi/4.svg'

import ricettaImage1 from 'assets/img/product-page/ricette/1.png'
import ricettaImage2 from 'assets/img/product-page/ricette/2.png'
import ricettaImage3 from 'assets/img/product-page/ricette/3.png'
import ricettaImage4 from 'assets/img/product-page/ricette/4.png'

import variantiImage1 from 'assets/img/product-page/varianti/1.png'
import variantiImage2 from 'assets/img/product-page/varianti/2.png'
import variantiImage3 from 'assets/img/product-page/varianti/3.png'
import variantiImage4 from 'assets/img/product-page/varianti/4.png'
import variantiImage5 from 'assets/img/product-page/varianti/5.png'
import variantiImage6 from 'assets/img/product-page/varianti/6.png'
import variantiImage7 from 'assets/img/product-page/varianti/7.png'
import variantiImage8 from 'assets/img/product-page/varianti/8.png'
import variantiImage9 from 'assets/img/product-page/varianti/9.png'
import variantiImage10 from 'assets/img/product-page/varianti/10.png'

// import lineaImage1 from 'assets/img/product-page/linea-sciccosa/1.png'
// import lineaImage2 from 'assets/img/product-page/linea-sciccosa/2.png'
// import lineaImage3 from 'assets/img/product-page/linea-sciccosa/3.png'
// import lineaImage4 from 'assets/img/product-page/linea-sciccosa/4.png'
import useProductData from 'hooks/useProductData'

// 'https://storage.googleapis.com/trusty-media-bucket/products/e652a38a-9443-40b3-9951-3f3ebacb7d29/a378853f-0c31-40ce-9829-1d4d30ef10f6.png'
const getProduct = () => {
  const { t } = useTranslation('productPage')
  const { product } = useProductData()

  // NOTE: decommentare quando si vuole rendere la pagina dinamica
  // const ingredientiAttr = product.attributes.find(x => x.name === 'Ingredienti')
  // const ingredientiSplitted = ingredientiAttr.value.split(';')
  // const ingredienti = ingredientiSplitted.map((ingr, index) => [index, ingr])

  const formats = product.metadata.formats.map(
    (form, index) => ({
      id: index + 1,
      text: <Trans
        key={index}
        t={t}
        i18nKey={`${product.companyCodArticle}.formats.data.${index + 1}`}
        components={{ 1: <div /> }}
            />,
      img: form.image_url
    })
  )

  // NOTE: decommentare quando si vuole rendere la pagina dinamica
  // const miniImg = product.metadata.attributes.filter(attrs => attrs.display_type === 'mini_image')
  // NOTE: decommentare quando si vuole rendere la pagina dinamica
  // const recipes = product.metadata.recipes.map((rec, index) => [index + 1, rec.title, rec.image_url, rec.value])

  return {
    productImage: product.profileImage.original,
    name: product.name,
    image1,
    // NOTE: decommentare quando si vuole rendere la pagina dinamica
    // image1: product.metadata.quality[0].image_url,
    // image3: product.metadata.quality[1].image_url,
    // image4: product.metadata.quality[2].image_url,
    image3: product.backgroundImage?.original,
    image4,
    toast: [
      [1, <Trans
        key={1}
        t={t}
        i18nKey='toasts.1'
        components={{ 1: <div />, 2: <b /> }}
          />],
      [2, <Trans
        key={2}
        t={t}
        i18nKey='toasts.2'
        components={{
          1: <div className='center-text link' />,
          2: <a href='#' id='link-tracciabilita' />
        }}
          />],
      [3, <Trans
        key={3}
        t={t}
        i18nKey='toasts.3'
        components={{
          1: <div />,
          2: <b />
        }}
          />]
    ],
    ingredienti: product.companyCodArticle === '5870100'
      ? [
          [1, t('5870100.accordion.ingredients.data.1')],
          [2, t('5870100.accordion.ingredients.data.2')],
          [3, t('5870100.accordion.ingredients.data.3')],
          [4, t('5870100.accordion.ingredients.data.4')],
          [5, t('5870100.accordion.ingredients.data.5')],
          [6, t('5870100.accordion.ingredients.data.6')]
        ]
      : [
          [1, t('5551100.accordion.ingredients.data.1')],
          [2, t('5551100.accordion.ingredients.data.2')],
          [3, t('5551100.accordion.ingredients.data.3')],
          [4, t('5551100.accordion.ingredients.data.4')],
          [5, t('5551100.accordion.ingredients.data.5')],
          [6, t('5551100.accordion.ingredients.data.6')]
        ],
    // NOTE: decommentare quando si vuole rendere la pagina dinamica
    // ingredienti,
    valoriNutrizionali: product.companyCodArticle === '5870100'
      ? [
          [1, t('5870100.accordion.nutritionalValues.data.1'), '1254/303 KJ/Kcal'],
          [2, t('5870100.accordion.nutritionalValues.data.2'), '27g'],
          [3, t('5870100.accordion.nutritionalValues.data.3'), '9,5g'],
          [4, t('5870100.accordion.nutritionalValues.data.4'), '0g'],
          [5, t('5870100.accordion.nutritionalValues.data.5'), '0g'],
          [6, t('5870100.accordion.nutritionalValues.data.6'), '15g'],
          [7, t('5870100.accordion.nutritionalValues.data.7'), '2,1g']
        ]
      : [
          [1, t('5551100.accordion.nutritionalValues.data.1'), '1251/302 KJ/Kcal'],
          [2, t('5551100.accordion.nutritionalValues.data.2'), '26g'],
          [3, t('5551100.accordion.nutritionalValues.data.3'), '10g'],
          [4, t('5551100.accordion.nutritionalValues.data.4'), '0g'],
          [5, t('5551100.accordion.nutritionalValues.data.5'), '0g'],
          [6, t('5551100.accordion.nutritionalValues.data.6'), '17g'],
          [7, t('5551100.accordion.nutritionalValues.data.7'), '1,9g']
        ],
    attributes: product.companyCodArticle === '5870100'
      ? [
          [1, t('5870100.accordion.attributes.data.1.title'), t('5870100.accordion.attributes.data.1.value')],
          [2, t('5870100.accordion.attributes.data.2.title'), '8007612000161'],
          [3, t('5870100.accordion.attributes.data.3.title'), t('5870100.accordion.attributes.data.3.value')],
          [4, t('5870100.accordion.attributes.data.4.title'), t('5870100.accordion.attributes.data.4.value')]
        ]
      : [
          [1, t('5551100.accordion.attributes.data.1.title'), t('5551100.accordion.attributes.data.1.value')],
          [2, t('5551100.accordion.attributes.data.2.title'), '8007612056217'],
          [3, t('5551100.accordion.attributes.data.3.title'), t('5551100.accordion.attributes.data.3.value')],
          [4, t('5551100.accordion.attributes.data.4.title'), t('5551100.accordion.attributes.data.4.value')]
        ],
    lineaSciccosa: formats,
    loghiCircle: {
      it: [product.metadata.quality[0].image_url, product.metadata.quality[1].image_url, product.metadata.quality[2].image_url],
      en: [enLogoCircle1, enLogoCircle2, enLogoCircle3]
    },
    slogan: t('slogan3'),
    loghi: [
      { title: t('logos.1.title'), subtitle: t('logos.1.subtitle'), img: logo1 },
      { title: t('logos.2.title'), subtitle: t('logos.2.subtitle'), img: logo2 },
      { title: t('logos.3.title'), subtitle: t('logos.3.subtitle'), img: logo3 },
      { title: t('logos.4.title'), subtitle: t('logos.4.subtitle'), img: logo4 }
    ],
    // loghi: miniImg.map(attrs => (
    //   {
    //     title: attrs.title,
    //     subtitle: attrs.value,
    //     img: attrs.image_url
    //   })
    // ),
    ricetteDescription: t('recipes.description'),
    ricette: [
      [1, t('recipes.data.1'), ricettaImage1, 'https://www.felsineo.com/ricetta/bouquet-di-asparagi/'],
      [2, t('recipes.data.2'), ricettaImage2, 'https://www.felsineo.com/ricetta/trofie-al-pesto-alla-genovese/'],
      [3, t('recipes.data.3'), ricettaImage3, 'https://www.felsineo.com/ricetta/passatelli-al-profumo-tartufo/'],
      [4, t('recipes.data.4'), ricettaImage4, 'https://www.felsineo.com/ricetta/frittelle-di-mortadella-e-parmigiano/']
    ],
    // NOTE: decommentare quando si vuole rendere la pagina dinamica
    // ricette: recipes,
    varianti: [
      [1, variantiImage1, 'https://www.felsineo.com/prodotto/1963-mortadella-artigianale/', t('goodness.data.1')],
      [2, variantiImage2, 'https://www.felsineo.com/prodotto/la-sincera/', t('goodness.data.2')],
      [3, variantiImage3, 'https://www.felsineo.com/prodotto/la-blu/', t('goodness.data.3')],
      [4, variantiImage4, 'https://www.felsineo.com/prodotto/amabile/', t('goodness.data.4')],
      [5, variantiImage5, 'https://www.felsineo.com/prodotto/primafetta/', t('goodness.data.5')],
      [6, variantiImage6, 'https://www.felsineo.com/prodotto/la-nera/', t('goodness.data.6')],
      [7, variantiImage7, 'https://www.felsineo.com/prodotto/la-rossa/', t('goodness.data.7')],
      [8, variantiImage8, 'https://www.felsineo.com/prodotto/rustica/', t('goodness.data.8')],
      [9, variantiImage9, 'https://www.felsineo.com/prodotto/oro/', t('goodness.data.9')],
      [10, variantiImage10, 'https://www.felsineo.com/prodotto/fior-demilia/', t('goodness.data.10')]
    ]
  }
}

export default getProduct
