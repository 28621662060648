import useProductData from 'hooks/useProductData'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation('traceabilityCard')
  const { product } = useProductData()
  const certifications = product.companyCodArticle === '5870100' ? [require('../assets/img/traceability-page/input-lots/e5f3133b-7ee0-4ee9-aa60-9d109fffa156/a.png').default] : [require('../assets/img/traceability-page/input-lots/e5f3133b-7ee0-4ee9-aa60-9d109fffa156/b.png').default]

  return {
    'e5f3133b-7ee0-4ee9-aa60-9d109fffa156': {
      id: 1,
      title: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.title`),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/e5f3133b-7ee0-4ee9-aa60-9d109fffa156/header.png').default,
        titolo: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.content.title`),
        testo: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.content.text`),
        prodotto: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.content.product`),
        certificazioni: certifications
      }
    },
    '3c41c792-c50b-4560-83d9-f0f1133dd6a8': {
      id: 1,
      title: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.title`),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/e5f3133b-7ee0-4ee9-aa60-9d109fffa156/header.png').default,
        titolo: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.content.title`),
        testo: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.content.text`),
        prodotto: t(`${product.companyCodArticle}.inputLots.e5f3133b-7ee0-4ee9-aa60-9d109fffa156.content.product`),
        certificazioni: certifications
      }
    }
  }
}
