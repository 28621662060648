import React from 'react'

import { useTranslation } from 'react-i18next'

import image from 'assets/img/not-found.png'

import './style.css'

const NotFound = (props) => {
  const { product, lot } = props
  const { t } = useTranslation('notFound')

  const missingLot = () => {
    return (
      <>
        <h1>{t('lot.title')}</h1>
        <p>{t('lot.text')}</p>
      </>
    )
  }

  const missingProduct = () => {
    return (
      <>
        <h1>{t('product.title')}</h1>
        <p>{t('product.text')}</p>
      </>
    )
  }

  const missingInformation = () => {
    return (
      <>
        <img src={image} alt='Not found' />
        <p>{t('information.text')}</p>
      </>
    )
  }

  return (
    <div id='page'>
      <div id='box'>
        {product
          ? missingProduct()
          : lot
            ? missingLot()
            : missingInformation()}
      </div>
    </div>
  )
}

export default NotFound
