import React, { createContext, useState } from 'react'

// import { useTranslation } from 'react-i18next'

import { getCompany } from 'actions/AppActions'
// import configuration from 'configuration'
import { alog } from 'utils/apioLog'

const CompanyContext = createContext()

export const CompanyProvider = ({ children }) => {
  const [company, setCompany] = useState({})
  const [companyLoaded, setCompanyLoaded] = useState(false)
  const [companyFound, setCompanyFound] = useState(false)
  //   const { t } = useTranslation('traceabilityCard')

  const makeCompany = async () => {
    try {
      alog('Data', 'fetching', 'DEBUG', 'info')
      const data = await getCompany('fe77d4ad-6cc1-46d2-98b1-02de0598ea9c')
      alog('Data', 'fetched', 'DEBUG', 'ok')
      setCompany(data)
      setCompanyLoaded(true)
      setCompanyFound(true)
      alog('Company', 'made', 'DEBUG', 'ok')
    } catch (err) {
      setCompanyLoaded(false)
      setCompanyFound(false)
      alog('Error: ', err, 'ERROR', 'error')
    }
  }

  return (
    <CompanyContext.Provider value={{
      company,
      companyLoaded,
      companyFound,
      makeCompany
    }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export default CompanyContext
