import React from 'react'

import { useTranslation } from 'react-i18next'

import ScreenRotationIcon from '@material-ui/icons/ScreenRotation'

import './style.css'

const RotationError = () => {
  const { t } = useTranslation('rotationError')

  return (
    <div id='rotation-error'>
      <div id='box'>
        <ScreenRotationIcon />
        <p>{t('text')}</p>
      </div>
    </div>
  )
}

export default RotationError
