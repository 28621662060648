import React, { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

const NavigationContext = createContext()

export const NavigationProvider = ({ children }) => {
  const [sectionActive, setSectionActive] = useState(1)
  const [callback, setCallback] = useState()

  const history = useHistory()

  const changeSection = (section, _callback = null) => {
    window.scrollTo(0, 0)

    if (_callback) {
      setCallback({
        consume: () => {
          _callback()
          setCallback()
        }
      })
    }

    setSectionActive(section)
  }

  // Porta alla sezione 4 e aggiunge /lot/LOT_ID all'url
  const viewTraceability = (lotNumber) => {
    setSectionActive(4)

    const currURL = window.location.pathname
    const newURL = currURL + (currURL.slice(-1) === '/' ? 'lot/' : '/lot/') + lotNumber

    history.push(newURL)
  }

  // Rimuove /lot/LOT_ID dall'url
  const resetURL = () => {
    const [gtinLabel, productID] = window.location.pathname.slice(1).split('/')
    const url = `/${gtinLabel}/${productID}`
    history.push(url)
  }

  return (
    <NavigationContext.Provider
      value={{
        sectionActive,
        changeSection,
        viewTraceability,
        callback,
        resetURL
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationContext
