import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Controller } from 'react-scrollmagic'

import { useTranslation, Trans } from 'react-i18next'

import useLotData from 'hooks/useLotData'
import useNavigation from 'hooks/useNavigation'
import useScreenOrientation from 'hooks/useScreenOrientation'

import { Container, Grid } from '@material-ui/core'

import LogoLoader from 'components/LogoLoader/LogoLoader'
import TraceabilityCard from 'components/TraceabilityCard/TraceabilityCard'
import NotFound from 'components/NotFound/NotFound'
import RotationError from 'components/RotationError/RotationError'
import FindLot from 'components/FindLot/FindLot'

import landImage from 'assets/img/traceability-page/land-image.png'
import prodottoImage from 'assets/img/traceability-page/prodotto.png'
import blockchainImage from 'assets/img/traceability-page/blockchain.svg'
import lastPageHeader from 'assets/img/traceability-page/last-page/header.png'
import lastLogo1 from 'assets/img/traceability-page/last-page/1.svg'
import lastLogo2 from 'assets/img/traceability-page/last-page/2.svg'
import lastLogo3 from 'assets/img/traceability-page/last-page/3.svg'

import BlockchainModal from './BlockchainModal'

const TraceabilityPage = () => {
  const { isLandscape } = useScreenOrientation()
  const { changeSection } = useNavigation()
  const { t } = useTranslation('traceabilityPage')
  const { lotNotFound, lotLoaded, lot, withLot } = useLotData()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleClick = () => {
    setIsOpenModal(true)
  }

  const handleIspirato = (e) => {
    e.preventDefault()
    changeSection(1, () => {
      document.getElementById('ricette').scrollIntoView()
    })
  }

  if (withLot) {
    if (lotNotFound) {
      return <NotFound lot />
    }
    if (!lotLoaded) {
      return <LogoLoader size='large' />
    }
    if (isMobileOnly && isLandscape) {
      return <RotationError />
    }
  }

  return (
    <>
      <div id='traceability-page'>
        <section id='header'>
          <div id='logo-circle'>
            <div id='circle'>
              <img src={prodottoImage} alt='' />
            </div>
          </div>
        </section>

        <section className='rounded-page'>

          {!withLot
            ? <Container>
              <FindLot />
              <div style={{ height: '30px' }} />
            </Container>
            : <>
              <BlockchainModal
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
              />
              <Container>
                <h1>{t('title')}</h1>
                <img
                  src={landImage}
                  style={{
                    width: '100%',
                    margin: '10px 0',
                    borderRadius: '34px'
                  }}
                  alt=''
                />
                <p>{t('text')}</p>
                <div style={{ height: '60px' }} />
              </Container>

              <section id='cards'>
                <Controller>
                  <>
                    {lot.traceability.map(card => <TraceabilityCard key={card.id} card={card} />)}
                  </>
                </Controller>
              </section>
              <section id='blockchain'>
                <Container>
                  <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={3}>
                      <img src={blockchainImage} alt='Blockchain' />
                    </Grid>
                    <Grid item xs={9}>
                      <p>
                        <Trans
                          t={t}
                          i18nKey='blockchain'
                          components={{ 1: <span /> }}
                        />
                      </p>
                    </Grid>
                  </Grid>
                  <div className='verifica'>
                    <button className='custom-button' onClick={handleClick}>{t('verify')}</button>
                  </div>
                </Container>
              </section>
              <section id='last-page'>
                <img src={lastPageHeader} alt='' />
                <Container>
                  <h3>{t('lastPage.text')}</h3>
                  <ul>
                    <li>
                      <img src={lastLogo1} alt='' />
                      <div>{t('lastPage.list.1')}</div>
                    </li>
                    <li>
                      <Container>
                        <img src={lastLogo2} alt='' />
                        <div>{t('lastPage.list.2')}</div>
                      </Container>
                    </li>
                    <li>
                      <img src={lastLogo3} alt='' />
                      <div>{t('lastPage.list.3')}</div>
                    </li>
                  </ul>
                  <p>{t('lastPage.inspiration.text')}</p>
                  <a href='#' onClick={handleIspirato}>{t('lastPage.inspiration.callToAction')}</a>
                </Container>
              </section>
            </>}
        </section>
      </div>
    </>
  )
}

export default TraceabilityPage
