import React from 'react'

import { useTranslation } from 'react-i18next'
import { Grid, Container } from '@material-ui/core'

import CustomModal from 'components/CustomModal/CustomModal'

import './style.scss'

const DettaglioFaseSlider = (props) => {
  const { item, isOpen, setIsOpen } = props

  const { t } = useTranslation('dettaglioFaseModal')

  return (
    <CustomModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className='dettaglio-fase-modal'
      closeButton
    >
      <section id='header'>
        <div className='image' style={{ backgroundImage: 'url(' + item.header + ')' }} />
      </section>

      <section className='rounded-page'>
        <Container>
          <h1>{item.titolo}</h1>
          <p>{item.testo}</p>
        </Container>

        <Container>
          <Grid container className='content'>
            {item.prodotto && <>
              <Grid item xs={12}>{t('distributor')}</Grid>
              <Grid item xs={12}>{item.prodotto}</Grid>
            </>}
            {item.certificazioni && <>
              <Grid item xs={12}>{t('certifications')}</Grid>
              <Grid item xs={12}>{item.certificazioni.map(cert => <img key={cert} src={cert} alt='' />)}</Grid>
            </>}
            {item.data && <>
              <Grid item xs={12}>{t('date')}</Grid>
              <Grid item xs={12}>{item.data}</Grid>
            </>}
          </Grid>
        </Container>
      </section>
    </CustomModal>
  )
}

export default DettaglioFaseSlider
