import { useTranslation } from 'react-i18next'

import header0 from 'assets/img/traceability-page/header-fasi/0.png'
import header1 from 'assets/img/traceability-page/header-fasi/1.png'
import header2 from 'assets/img/traceability-page/header-fasi/2.png'
import header3 from 'assets/img/traceability-page/header-fasi/3.png'

import dettaglioHeader2 from 'assets/img/traceability-page/header-fasi/01.png'

import dettaglioCover2 from 'assets/img/traceability-page/cover-fasi/2.png'
import dettaglioCover3 from 'assets/img/traceability-page/cover-fasi/3.png'
import dettaglioCover4 from 'assets/img/traceability-page/cover-fasi/4.png'
import dettaglioCover5 from 'assets/img/traceability-page/cover-fasi/5.png'
import useProductData from 'hooks/useProductData'

export default () => {
  const { t } = useTranslation('traceabilityCard')

  const { product } = useProductData()
  if (product.companyCodArticle === '5870100') {
    return {
      traceability: [
        {
          id: 0,
          title: t(`${product.companyCodArticle}.cards.0.title`),
          header: header0,
          dettaglio: []
        },
        {
          id: 1,
          title: t(`${product.companyCodArticle}.cards.1.title`),
          header: header1,
          dettaglio: [
            {
              id: 1,
              title: t(`${product.companyCodArticle}.cards.1.detail.title`),
              cover: dettaglioCover2,
              content: {
                type: 'B',
                header: dettaglioHeader2,
                titolo: t(`${product.companyCodArticle}.cards.1.detail.content.title`),
                testo: t(`${product.companyCodArticle}.cards.1.detail.content.text`)
              }
            }
          ]
        },
        {
          id: 2,
          title: t(`${product.companyCodArticle}.cards.2.title`),
          header: header2,
          dettaglio: {
            circleCover: dettaglioCover3
          }
        },
        {
          id: 3,
          title: t(`${product.companyCodArticle}.cards.3.title`),
          header: header3,
          dettaglio: {
            circleCover: dettaglioCover4
          }
        }
      ]
    }
  } else {
    return {
      traceability: [
        {
          id: 0,
          title: t(`${product.companyCodArticle}.cards.0.title`),
          header: header0,
          dettaglio: []
        },
        {
          id: 1,
          title: t(`${product.companyCodArticle}.cards.1.title`),
          header: header1,
          dettaglio: [
            {
              id: 1,
              title: t(`${product.companyCodArticle}.cards.1.detail.title`),
              cover: dettaglioCover2,
              content: {
                type: 'B',
                header: dettaglioHeader2,
                titolo: t(`${product.companyCodArticle}.cards.1.detail.content.title`),
                testo: t(`${product.companyCodArticle}.cards.1.detail.content.text`)
              }
            }
          ]
        },
        {
          id: 2,
          title: t(`${product.companyCodArticle}.cards.2.title`),
          header: header2,
          dettaglio: {
            circleCover: dettaglioCover3
          }
        },
        {
          id: 3,
          title: t(`${product.companyCodArticle}.cards.3.title`),
          header: header3,
          dettaglio: {
            circleCover: dettaglioCover5
          }
        }
      ]
    }
  }
}
