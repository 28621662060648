import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import preval from 'preval.macro'

import { LotProvider } from 'context/LotContext'
import { ProductProvider } from 'context/ProductContext'
import { CompanyProvider } from 'context/CompanyContext'
import { NavigationProvider } from 'context/NavigationContext'

import NotFound from 'components/NotFound/NotFound'

import { alog } from 'utils/apioLog'

import RootPage from 'RootPage'

import './i18n'

const App = () => {
  // Stampa in console la data dell'ultima build dell'app
  const buildDate = preval`
  const moment = require('moment-timezone')
  const buildTimestamp = moment().tz("Europe/Rome").format('DD/MM/YYYY HH:mm:ss')
  module.exports = buildTimestamp
  `
  alog('build date', buildDate, 'config', 'ok')

  return (
    <BrowserRouter>
      <Switch>
        <Route path={['/gtin/:gtin', '/gtin/:gtin/lot/:lotId']} exact>
          <NavigationProvider>
            <ProductProvider>
            <CompanyProvider>
              <LotProvider>
                <RootPage />
              </LotProvider>
              </CompanyProvider>
            </ProductProvider>
          </NavigationProvider>
        </Route>
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
