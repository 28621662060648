import React, { createContext, useState, useEffect } from 'react'

// import { useTranslation } from 'react-i18next'

import { getProduct } from 'actions/AppActions'
import configuration from 'configuration'
import { alog } from 'utils/apioLog'

const ProductContext = createContext()

export const ProductProvider = ({ children }) => {
  const [product, setProduct] = useState({})
  const [productLoaded, setProductLoaded] = useState(false)
  const [productFound, setProductFound] = useState(true)
  //   const { t } = useTranslation('traceabilityCard')

  useEffect(() => {
    (product?.companyId === configuration.companyId) &&
    setProductLoaded(true)
  }, [product])

  const makeProduct = async (gtin) => {
    try {
      alog('Data', 'fetching', 'DEBUG', 'info')
      const data = await getProduct(gtin)
      alog('Data', 'fetched', 'DEBUG', 'ok')
      setProduct(data)
      setProductFound(true)
      alog('Produxt', 'made', 'DEBUG', 'ok')
    } catch (err) {
      setProductFound(false)
      alog('Error: ', err, 'ERROR', 'error')
    }
  }

  return (
    <ProductContext.Provider value={{
      product,
      productLoaded,
      productFound,
      makeProduct
    }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export default ProductContext
