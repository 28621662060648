import React, { Fragment, useState, useEffect } from 'react'

import useLotData from 'hooks/useLotData'
import useNavigation from 'hooks/useNavigation'
import { useTranslation, Trans } from 'react-i18next'

import {
  Grid,
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Snackbar,
  Slide
} from '@material-ui/core'

import { ExpandMore } from '@material-ui/icons'

import logo1 from 'assets/img/product-page/loghi-accordion/1.svg'
import logo2 from 'assets/img/product-page/loghi-accordion/2.svg'
import logo3 from 'assets/img/product-page/loghi-accordion/3.svg'

import arrowIcon from 'assets/img/product-page/arrow.svg'

import RicetteSlider from 'components/RicetteSlider/RicetteSlider'
import FindLot from 'components/FindLot/FindLot'
import CustomModal from 'components/CustomModal/CustomModal'

import felsineoLogo from 'assets/img/felsineo-logo.png'

import getProduct from 'data/product'
import useProductData from 'hooks/useProductData'

const ProductPage = () => {
  const product = getProduct()
  const { t, i18n } = useTranslation('productPage')

  const { product: productAPI } = useProductData()
  console.log('i18n', i18n)
  const [expanded, setExpanded] = useState(false)
  const [toastOpen, setToastOpen] = useState()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { lotLoaded } = useLotData()
  const { changeSection } = useNavigation()

  const keyLanguage = i18n.language.split('-')[0]

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleCloseToast = () => {
    setToastOpen()
  }

  const handleClickToast = (e) => {
    e.preventDefault()
    setToastOpen(e.target.className)
  }

  const handleClickModal = (e) => {
    e.preventDefault()
    setIsOpenModal(true)
  }

  useEffect(() => {
    if (toastOpen === 'toast2') {
      const el = document.getElementById('link-tracciabilita')
      if (el) {
        el.onclick = (e) => {
          e.preventDefault()
          changeSection(4)
        }
      }
    }
  }, [toastOpen])

  return (
    <>
      {product.toast.map(([id, content]) =>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          key={id}
          open={toastOpen === ('toast' + id)}
          TransitionComponent={Slide}
          onClose={handleCloseToast}
          action={<div className='toast'>{content}</div>}
        />
      )}

      <CustomModal
        className='plastica-modal'
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        closeButton
      >
        <div id='content'>
          <div className='center-text'><img src={product.image4} alt='' /></div>
          <Container>
            <p>
              <Trans
                t={t}
                i18nKey='plasticaModal.text'
                components={{ 1: <b /> }}
              />
            </p>
          </Container>
        </div>
      </CustomModal>

      <div id='product-page'>
        <section id='header'>
          <article>
            <Container>
              <Grid container alignItems='center' spacing={1} style={{ padding: '10vw 0' }}>
                <Grid item xs={9}>
                  <h1 id='product-title'>{product.name}</h1>
                </Grid>
                <Grid item xs={3}>
                  <div id='felsineo-logo'><img src={felsineoLogo} alt='logo' /></div>
                </Grid>
              </Grid>
            </Container>
          </article>

          <article>
            <Container>
              <Grid container alignItems='center'>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    {product.loghiCircle[keyLanguage].map((logo, i) => (
                      <Grid item key={logo} xs={12}>
                        <div className='logo'>
                          <a href='#' onClick={handleClickToast}>
                            <img src={logo} className={'toast' + (i + 1)} alt='' />
                          </a>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <div id='product-image'>
                    <span id='circle' />
                    <img src={product.productImage} alt='' />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </article>

          {productAPI.companyCodArticle === '5870100' && <article id='description'>
              <Container>
                <p>
                  <Trans
                    t={t}
                    i18nKey='5870100.productDescription'
                    components={{ 1: <span /> }}
                  />
                </p>
              </Container>
          </article>
          }

          {['5551100', '5251100'].includes(productAPI.companyCodArticle) && <article id='description'>
              <Container>
                <p>
                  <Trans
                    t={t}
                    i18nKey='5551100.productDescription'
                    components={{ 1: <span /> }}
                  />
                </p>
              </Container>
          </article>
          }

        </section>
        <section className='rounded-page'>

          {!lotLoaded &&
            <article id='find-lot'>
              <Container>
                <FindLot />
              </Container>
            </article>}

          {productAPI.companyCodArticle === '5870100' && <article id='sciccosa-line'>
            <Container>
              <h3>{t('5870100.formats.title')}</h3>
              <Grid container spacing={3}>
                {product.lineaSciccosa.map(item => (
                  <Grid item key={item.id} xs={6}>
                    <img src={item.img} alt='' />
                    {item.text}
                  </Grid>
                ))}
              </Grid>
            </Container>
          </article> }

          {['5551100', '5251100'].includes(productAPI.companyCodArticle) && <article id='kmrosa-line'>
            <Container>
              <h3>{t('5551100.formats.title')}</h3>
              <Grid container spacing={3}>
                {product.lineaSciccosa.map(item => (
                  <Grid item key={item.id} xs={6}>
                    <img src={item.img} alt='' />
                    {item.text}
                  </Grid>
                ))}
              </Grid>
            </Container>
          </article> }

          <article id='accordion'>
            <Accordion elevation={0} expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                {productAPI.companyCodArticle === '5870100' && <Typography><img src={logo1} />{t('5870100.accordion.ingredients.title')}</Typography>}
                {['5551100', '5251100'].includes(productAPI.companyCodArticle) && <Typography><img src={logo1} />{t('5551100.accordion.ingredients.title')}</Typography>}
              </AccordionSummary>
              <AccordionDetails>
                <Container>
                  <table id='ingredienti'>
                    <tbody>
                      {product.ingredienti.map(([id, text]) => (
                        <tr key={id}><td>{text}</td></tr>
                      ))}
                    </tbody>
                  </table>
                </Container>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel2-content'
                id='panel2-header'
              >
                {productAPI.companyCodArticle === '5870100' && <Typography><img src={logo2} />{t('5870100.accordion.nutritionalValues.title')}</Typography>}
                {['5551100', '5251100'].includes(productAPI.companyCodArticle) && <Typography><img src={logo2} />{t('5551100.accordion.nutritionalValues.title')}</Typography>}
              </AccordionSummary>
              <AccordionDetails>
                <Container>
                  <table id='valori-nutrizionali'>
                    <tbody>
                      {product.valoriNutrizionali.map(([id, title, value]) => (
                        <tr key={id}>
                          <td>{title}</td>
                          <td>{value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Container>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel3-content'
                id='panel3-header'
              >
                {productAPI.companyCodArticle === '5870100' && <Typography><img src={logo3} />{t('5870100.accordion.attributes.title')}</Typography>}
                {['5551100', '5251100'].includes(productAPI.companyCodArticle) && <Typography><img src={logo3} />{t('5551100.accordion.attributes.title')}</Typography>}
              </AccordionSummary>
              <AccordionDetails>
                <Container>
                  <div id='caratteristiche'>
                    {product.attributes.map(([id, title, value]) =>
                      <div className='item' key={id}>
                        <div className='title'>{title}</div>
                        <div className='value'>{value}</div>
                      </div>
                    )}
                  </div>
                </Container>
              </AccordionDetails>
            </Accordion>
          </article>

          {/* <article id='plastica'>
            <Grid container alignItems='center' spacing={1}>
              <Grid item xs={5}>
                <div id='product-image'>
                  <span className='circle' />
                  <img src={product.image4} alt='' />
                </div>
              </Grid>
              <Grid item xs={7}>
                <div style={{ width: '90%' }}>
                  <h2 style={{ margin: '5px 0' }}>
                    <Trans
                      t={t}
                      i18nKey='slogan2.text'
                      components={{ sup: <sup /> }}
                    />
                  </h2>
                  <div><a href='!#' onClick={handleClickModal}>{t('slogan2.button')} <img src={arrowIcon} alt='' /></a></div>
                  <div style={{ margin: '10px 0' }}><small>{t('slogan2.subtitle')}</small></div>
                </div>
              </Grid>
            </Grid>
          </article>

          <article>
            <Container>
              <div className='center-text'>
                <img src={product.image1} style={{ width: '70%', marginBottom: '30px' }} alt='' />
              </div>
              <h3>{t('slogan1.title')}</h3>
              <p>{t('slogan1.text')}</p>
            </Container>
          </article> */}

          {productAPI.companyCodArticle === '5870100' &&
            <>
              <article id='plastica'>
                <Grid container alignItems='center' spacing={1}>
                  <Grid item xs={5}>
                    <div id='product-image'>
                      <span className='circle' />
                      <img src={product.image4} alt='' />
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{ width: '90%' }}>
                      <h2 style={{ margin: '5px 0' }}>
                        <Trans
                          t={t}
                          i18nKey='slogan2.text'
                          components={{ sup: <sup /> }}
                        />
                      </h2>
                      <div><a href='!#' onClick={handleClickModal}>{t('slogan2.button')} <img src={arrowIcon} alt='' /></a></div>
                      <div style={{ margin: '10px 0' }}><small>{t('slogan2.subtitle')}</small></div>
                    </div>
                  </Grid>
                </Grid>
              </article>

              <article>
                <Container>
                  <div className='center-text'>
                    <img src={product.image1} style={{ width: '70%', marginBottom: '30px' }} alt='' />
                  </div>
                  <h3>{t('slogan1.title')}</h3>
                  <p>{t('slogan1.text')}</p>
                </Container>
              </article>
            </>}

          <article style={{ marginTop: '80px', marginBottom: '60px' }}>
            <Container>
              <img src={product.image3} style={{ width: '100%' }} alt='' />
            </Container>
          </article>

          <article>
            <Container className='center-text'>
              <h3>{product.slogan}</h3>
            </Container>
          </article>

          <article id='loghi'>
            <Container>
              <Grid container>
                {product.loghi.map(({ title, subtitle, img }) =>
                  <Fragment key={title}>
                    <Grid item xs={6}>
                      <div className='logo'>
                        <img src={img} alt='' />
                        <div className='title'>{title}</div>
                        <div className='subtitle'>{subtitle}</div>
                      </div>
                    </Grid>
                  </Fragment>)}
              </Grid>
            </Container>
          </article>

          <article style={{ margin: '70px 0' }}>
            <Container>
              <h3>{product.ricetteDescription}</h3>
            </Container>
          </article>

          <article id='ricette'>
            <Container>
              <h3>{t('recipes.title')}</h3>
            </Container>
            <RicetteSlider items={product.ricette} />
          </article>

          <article id='varianti'>
            <Container>
              <h3>{t('goodness.title')}</h3>
              {productAPI.companyCodArticle === '5870100' && <p>{t('goodness.text1')}</p>}
              {productAPI.companyCodArticle === '5870100' && <p>{t('goodness.text2')}</p>}
            </Container>

            <ul className='image-slider'>
              {product.varianti.map(([id, image, link, text]) => (
                <li key={id}>
                  <a href={link} target='_blank' rel='noreferrer'>
                    <img src={image} alt='' />
                    <p>{text}</p>
                  </a>
                </li>
              ))}
            </ul>
          </article>

        </section>
      </div>
    </>
  )
}

export default ProductPage
