// import img1 from 'assets/img/certifications-page/1.png'
// import img2 from 'assets/img/certifications-page/2.png'
// import img3 from 'assets/img/certifications-page/3.png'
// import img4 from 'assets/img/certifications-page/4.png'
// import img5 from 'assets/img/certifications-page/5.png'
// import img6 from 'assets/img/certifications-page/6.png'
// import img7 from 'assets/img/certifications-page/7.png'
import useProductData from 'hooks/useProductData'

export default () => {
  const { product } = useProductData()

  return product.codCompanyAziendale === '5870100'
    ? [
        {
          id: 1,
          logo: product.certifications[0].image.original,
          link: {
            title: product.certifications[0].name,
            url: product.certifications[0].documents[0].fileUrl
          }
        },
        {
          id: 2,
          logo: product.certifications[1].image.original,
          link: {
            title: product.certifications[1].name,
            url: product.certifications[1].documents[0].fileUrl
          }
        },
        {
          id: 3,
          logo: product.certifications[2].image.original,
          link: {
            title: product.certifications[2].name,
            url: product.certifications[2].documents[0].fileUrl
          }
        },
        {
          id: 4,
          logo: product.certifications[3].image.original,
          link: {
            title: product.certifications[3].name,
            url: product.certifications[3].documents[0].fileUrl
          }
        },
        {
          id: 5,
          logo: product.certifications[4].image.original,
          link: {
            title: product.certifications[4].name,
            url: product.certifications[4].documents[0].fileUrl
          }
        },
        {
          id: 6,
          logo: product.certifications[5].image.original,
          link: {
            title: product.certifications[5].name,
            url: product.certifications[5].documents[0].fileUrl
          }
        },
        {
          id: 7,
          logo: product.certifications[6].image.original,
          link: {
            title: product.certifications[6].name,
            url: product.certifications[6].documents[0].fileUrl
          }
        }
      ]
    : [
        {
          id: 1,
          logo: product.certifications[0].image.original,
          link: {
            title: product.certifications[0].name,
            url: product.certifications[0].documents[0].fileUrl
          }
        },
        {
          id: 2,
          logo: product.certifications[1].image.original,
          link: {
            title: product.certifications[1].name,
            url: product.certifications[1].documents[0].fileUrl
          }
        },
        {
          id: 3,
          logo: product.certifications[2].image.original,
          link: {
            title: product.certifications[2].name,
            url: product.certifications[2].documents[0].fileUrl
          }
        },
        {
          id: 4,
          logo: product.certifications[3].image.original,
          link: {
            title: product.certifications[3].name,
            url: product.certifications[3].documents[0].fileUrl
          }
        },
        {
          id: 5,
          logo: product.certifications[4].image.original,
          link: {
            title: product.certifications[4].name,
            url: product.certifications[4].documents[0].fileUrl
          }
        },
        {
          id: 7,
          logo: product.certifications[6].image.original,
          link: {
            title: product.certifications[6].name,
            url: product.certifications[6].documents[0].fileUrl
          }
        },
        {
          id: 99,
          logo: product.certifications[5].image.original,
          link: {
            // title: product.certifications[5].name,
            url: product.certifications[5].documents[0].fileUrl
          }
        }
      ]
}
